import {
  HydrateWhenInViewport,
  Image,
  Link,
  Logos,
  PageContainer,
  Paragraph,
  Title,
} from '@loveholidays/design-system';
import React from 'react';

import { MobileAppUspComponent as MobileAppUspComponentProps } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { Icon } from '@Components/Icon/Icon';
import { useAppContext } from '@Contexts/contexts';
import { DesignSystemAsset } from '@DesignSystemComponents/DesignSystemAsset';
import { hastToReact } from '@UX/Hast/Hast';

export const MobileAppUspComponent: React.FC<MobileAppUspComponentProps & ClassNameProps> = ({
  className,
  mobileAppUspContent,
}) => {
  const { publicPath, site } = useAppContext();
  const { title, content, links } = mobileAppUspContent;

  return (
    <HydrateWhenInViewport>
      <section
        className={className}
        sx={{
          backgroundColor: 'white',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <PageContainer
          sx={{
            position: 'relative',
            paddingTop: '4xl',
            paddingBottom: [null, '4xl'],
          }}
        >
          <Icon
            name="BlueRay"
            width={1400}
            sx={{
              position: 'absolute',
              bottom: '-400px',
              right: ['-750px', '-1000px', '-750px'],
            }}
          />
          <div
            sx={{
              display: ['block', 'flex'],
              justifyContent: 'center',
              alignItems: 'center',
              paddingX: [null, 'xs'],
            }}
          >
            <div
              sx={{
                width: ['100%', '50%', '60%'],
                position: 'relative',
                zIndex: 1,
              }}
            >
              <Title
                variant="medium"
                as="h3"
                sx={{
                  marginBottom: ['l', null, 'xl'],
                }}
              >
                {title}
              </Title>
              {hastToReact(content, {
                p: {
                  Component: Paragraph,
                  Props: {
                    sx: () => ({
                      marginBottom: ['l', null, 'xl'],
                    }),
                    variant: 'large',
                    as: 'paragraph',
                  },
                },
              })}
              <div>
                <div sx={{ display: 'flex', flexDirection: 'row' }}>
                  {links
                    .filter((link) => !!link.logo)
                    .map((link) => (
                      <Link
                        key={link.label}
                        href={link.url!}
                        target={link.target}
                        rel={link.rel}
                        title={link.label}
                        sx={{
                          marginRight: 's',
                          marginBottom: [null, 's'],
                        }}
                        trackingAction="mobile-app-usp"
                        eventLabel={link.label}
                      >
                        <DesignSystemAsset
                          height={['40', '48', '64']}
                          type="Logo"
                          name={link.logo as Logos}
                        />
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            <div
              sx={{
                width: [null, '60%'],
                marginLeft: [null, '3xs'],
                minHeight: ['260px', 0],
              }}
            >
              <Image
                className={className}
                fluid={false}
                sx={{
                  maxWidth: ['500px', '500px', '700px'],
                  maxHeight: ['400px', '100%'],
                  objectFit: 'contain',
                  position: ['relative', 'absolute'],
                  bottom: ['-3xs', 0],
                  right: ['150px', 0],
                  height: 'auto',
                }}
                src={`${publicPath}Images/app-${site.siteCode}.png`}
                width={700}
                height={582}
                alt=""
              />
            </div>
          </div>
        </PageContainer>
      </section>
    </HydrateWhenInViewport>
  );
};

export default MobileAppUspComponent;
